import '../scss/modalWindow.scss';
export var modalWindow = function (selector) {
    var modal = document.querySelector(selector);
    if (modal) {
        var underlay = modal.querySelector('.underlay');
        var openBtn = document.querySelector('#is-modalOpen');
        var closeBtn = modal.querySelector('#is_modalClose');
        var config = new KeyframeEffect(modal, {
            opacity: [0, 1]
        }, {
            duration: 600,
            easing: 'ease',
            fill: 'forwards',
            iterations: 1
        });
        var animation_1 = new Animation(config);
        openBtn.addEventListener('click', function () {
            modal.style.display = 'block';
            animation_1.playbackRate = 1;
            animation_1.play();
            animation_1.onfinish = function () { return (modal.style.display = 'block'); };
        });
        closeBtn.addEventListener('click', function () {
            animation_1.reverse();
            animation_1.onfinish = function () { return (modal.style.display = 'none'); };
        });
        underlay.addEventListener('click', function () {
            animation_1.reverse();
            animation_1.onfinish = function () { return (modal.style.display = 'none'); };
        });
    }
};
